@tailwind base;
@tailwind components;
@tailwind utilities;

.text-color-primary {
  color: #384865;
}

.background-color-primary {
  background-color: #384865;
}


.btn-primary {
  @apply background-color-primary text-white font-semibold py-2 px-4 rounded-lg hover:opacity-70 cursor-pointer;
}

.btn-plain-primary {
  @apply text-color-primary font-semibold py-1 px-2 rounded-lg hover:bg-slate-300 cursor-pointer;
}


.txt-title {
  @apply  text-xl sm:text-3xl font-semibold text-color-primary;
}

.txt-subtitle {
  @apply text-sm sm:text-xl font-semibold text-color-primary;
}

.txt-normal {
  @apply text-[16px] text-color-primary;
}

.txt-content {
  @apply text-sm leading-tight text-slate-500;
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Độ rộng của thanh cuộn */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Màu nền của thanh cuộn */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #bbb; /* Màu của phần trượt */
  border-radius: 4px; /* Bo góc */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #aaa; /* Màu khi hover */
}